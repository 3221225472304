.service__nav-box {
    background: rgba(49, 49, 49, .75);
    height: 100%;
    overflow: hidden;
}

.service__title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--l-font);
    padding: 2rem 4rem 4rem;
}

.service__title h1 {
    font-size: clamp(2em, 12vw, 4em);
}

.service__sections {
    display: grid;
    place-items: center;
    overflow: hidden;
}

.service__section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    padding: 2rem 2rem;
}

.service__section:nth-of-type(even) {
    display: flex;
    flex-direction: row-reverse;
}

@media screen and (max-width: 960px) {

    .service__title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--l-font);
        padding: 5rem 4rem 5rem;
    }
}

@media screen and (max-width: 600px) {

    .service__title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--l-font);
        padding: 4rem 4rem 4rem;
    }

    .service__section {
        flex-direction: column;
        padding: 2rem 2rem 1rem;
    }

    .service__section:nth-of-type(even) {
        flex-direction: column;
    }
}

@media screen and (max-width: 500px) {

    .service__section {
        flex-direction: column;
        padding: 2rem 2rem 1rem;
    }

    .service__section:nth-of-type(even) {
        flex-direction: column;
    }
}

@media screen and (max-width: 320px) {
    .service__title {
        font-size: 1em;
    }
}

.service__line {
    height: 1px;
    width: 80%;
    max-width: 850px;
    background: #c3c3c3;
}