.error__page-contaniner {
    background: rgba(0, 0, 0, .5);
}

.error__page {
    height: calc(100vh - 105px);
    display: grid;
    place-items: center;
}

.error__page-box {
    background: rgba(255, 255, 255, 1);
    padding: 2rem 3rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error__page h1 {
    color: var(--d-font);
    font-size: 2em;
    text-align: center;
    margin-bottom: .5rem;
}

.error__page p {
    opacity: .4;
    text-align: center;
}

@media screen and (max-width: 610px) {
    .error__page-box {
        padding: 1rem 2rem;
    }

    .error__page h1 {
        font-size: 1.8em;
        margin-bottom: 1rem;
    }

}

@media screen and (max-width: 500px) {
    .error__page {
        height: calc(100vh - 75px);
    }
}

@media screen and (max-width: 400px) {
    .error__page-box {
        padding: 1.5rem 1rem;
    }

    .error__page h1 {
        font-size: 1.6em;
    }

    .error__page p {
        font-size: .9em;
    }
}