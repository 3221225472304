.service__section-title {
    color: var(--d-font);
    margin: .8rem 0;
}

.service__section-text {
    color: var(--ld-font);
    line-height: 1.7rem;
}

@media screen and (max-width: 600px) {
    .service__section-title {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 500px) {

    .service__section-title {
        text-align: center;
    }
}