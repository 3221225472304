:root {
  --d-font: #252525;
  --ld-font: #656565;
  --l-font: #F5F5F5;
  --l-gray: #F5F5F5;
  --d-gray: #a5a5a5;
  --l-brown: #F2EAD3;
  --m-brown: #DFD7BF;
  --d-brown: #775f4e;
  --font-family: font-family: 'Tektur', sans-serif;
  --input-f-s: .9rem;

}

::placeholder {
  color: rgb(169, 169, 169);
}

body {
  background: var(--l-gray);
}

* {
  font-family: 'Tektur', sans-serif;
}