.clicked-reference-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    z-index: 5;
    background: rgba(0, 0, 0, .9);
}

.clicked-reference-box {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.clicked-reference-button {
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(100%, -100%);
    background: transparent;
    border: none;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
}

.clicked-reference-button:hover span {
    scale: 1.1;
}

.clicked-reference-button span {
    color: var(--l-font);
    font-size: 3rem;
    animation: shake1 0.8s ease-in-out .5s;
    transition: .1s ease-in-out;
}

.clicked-reference-img {
    max-width: 75vw;
    max-height: 700px;
    object-fit: contain;
}

@keyframes shake1 {

    0%,
    100% {
        transform: rotate(0deg);
    }

    15%,
    45%,
    75% {
        transform: rotate(8deg);
        scale: 1.1;
    }

    30%,
    40%,
    60%,
    90% {
        transform: rotate(-8deg);
        scale: 1.1;
    }
}

.reference__container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reference__nav-box {
    background: rgba(49, 49, 49, .75);
    height: 100%;
    overflow: hidden;
}

.reference__title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--l-font);
    padding: 3rem 3rem 2rem;
}

.reference__title h1 {
    font-size: clamp(2em, 12vw, 4em);
}

.reference__filter-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 3rem 1rem;
    gap: .8rem;
    max-width: 1100px;
}

.reference__filter-button {
    padding: .05rem .4rem;
    background: var(--l-gray);
    color: var(--d-font);
    font-size: 1.2em;
    border: 1px solid var(--l-gray);
    border-radius: 5px;
    cursor: pointer;
}

.reference__filter-button.active {
    background: var(--d-brown);
    border: 1px solid var(--d-brown);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, .3);
    color: var(--l-font);
    font-weight: 500;
}

.reference__filter-button.active:hover {
    background: var(--d-brown);
    border: 1px solid var(--d-brown);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, .3);
}

.reference__filter-button:hover {
    background: #cfcfcf;
    border: 1px solid #cfcfcf;
}








.reference__container {
    overflow: hidden;
}

.reference__photo-container {
    display: grid;
    place-items: center;
    padding: .5rem 1rem 2rem;
    overflow: hidden;
}

.reference__photo-all {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem 0;
}





@media screen and (max-width: 960px) {

    .reference__filter-buttons {
        gap: .5rem;
    }

    .reference__filter-button {
        font-size: 1.1em;
    }

    .reference__photo-container {
        padding: .5rem 1rem 1.5rem;
    }
}

@media screen and (max-width: 500px) {
    .reference__title {
        padding: 3rem 2rem 2rem;
    }

    .clicked-reference-button {
        transform: translate(85%, -85%);
    }

    .clicked-reference-button span {
        font-size: 2.5rem;
    }

    .reference__filter-buttons {
        padding: 1.5rem 1rem 1rem;
    }

    .reference__filter-button {
        font-size: .9em;
    }

    .reference__photo-container {
        padding: .5rem 1rem 1rem;
    }
}