.pageup_div {
    display: grid;
    place-items: center;
    bottom: 5rem;
    right: 3rem;
    height: 50px;
    width: 50px;
    border-radius: 8px;
    position: fixed;
    background: rgba(25, 25, 25, 0.7);
    cursor: pointer;
    z-index: 3;
}

.pageup_icon {
    font-size: 2.8em;
    color: var(--l-font);
}

@media screen and (max-width: 400px) {
    .pageup_div {
        bottom: 2rem;
        right: 1.5rem;
    }
}