.service__section-img {
    margin-right: 2rem;
    height: 100%;
    max-height: 250px;
    min-width: 180px;
    overflow: hidden;
    border-radius: 6px;
    aspect-ratio: 4/5;
}

.service__section:nth-of-type(even) .service__section-img {
    margin-right: 0;
    margin-left: 2rem;
}

.service__section-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
}

@media screen and (max-width: 600px) {
    .service__section-img {
        margin: 0;
        width: 100%;
    }

    .service__section:nth-of-type(even) .service__section-img {
        margin: 0;
    }
}