.contact__title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    color: var(--l-font);
    font-size: 3em;
}

.contact__contactform-box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 1rem 2rem 2rem;
    min-height: calc(100vh - 105px);
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.contact__contactform {
    padding: 1rem;
    width: 100%;
    min-width: 260px;
    max-width: 400px;
    border-radius: 4px;
    background: var(--l-gray);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .2);
}

.contact-form-title {
    color: var(--d-font);
    margin-bottom: 1rem;
    text-align: center;
}

.contact__contacts {
    padding: 1rem;
    border-radius: 4px;
    color: var(--l-font);
}

.contact__contacts-title {
    font-size: 3rem;
    margin-bottom: 2rem;
}

.contact__contacts-email-box,
.contact__contacts-number-box,
.contact__contacts-schedule-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 .8rem;
    margin-bottom: 1rem;
}

.contact__contacts-email-icon,
.contact__contacts-number-icon,
.contact__contacts-schedule-icon {
    font-size: 2.2em;
}

.contact__contacts-email,
.contact__contacts-number,
.contact__contacts-schedule {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--l-font);
}


@media screen and (max-width: 800px) {
    .contact__title {
        display: none;
    }

    .contact__contactform-box {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 500px) {
    .contact__title {
        font-size: 2.5em;
        height: 12vh;
    }

    .contact__contactform-box {
        padding: 1rem 1rem 2rem;
        min-height: calc(100% - 75px);
    }

    .contact__contacts-title {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 320px) {
    .contact__title {
        font-size: 2em;
    }

    .contact__contacts-title {
        font-size: 1.9rem;
    }

    .contact__contacts-email-icon,
    .contact__contacts-number-icon,
    .contact__contacts-schedule-icon {
        font-size: 1.5em;
    }

    .contact__contacts-email,
    .contact__contacts-number,
    .contact__contacts-schedule {
        font-size: 1rem;
    }
}