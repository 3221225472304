.home__hero-box {
    display: flex;
    justify-content: center;
    color: var(--l-font);
    min-height: calc(100vh - 105px);
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 0;
}

.home__title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
    padding: 2rem 1rem;
    color: var(--l-font);
}

.home__subtitle {
    font-size: 1.8rem;
}

.home__title {
    margin: 1rem 0;
    font-size: 3.2rem;
}

.home__p {
    font-size: 1.2rem;
    line-height: 2rem;
}

.home__service-button,
.home__reference-button {
    background: var(--m-brown);
    text-decoration: none;
    font-weight: 500;
    color: var(--d-font);
    padding: .4rem 1rem;
    border-radius: 1.5rem;
    margin: 2rem 0;
}

.home__contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__contact-title {
    color: var(--d-font);
    text-align: center;
    margin-bottom: 1rem;
}

.home__contact-box {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    width: 100%;
    background: var(--l-gray);
    color: var(--d-font);
    z-index: 2;
}

.home__contact-pn-box {
    background: var(--d-brown);
    width: 100%;
    text-align: center;
    padding: 1.2rem 0 .8rem;
    border-radius: 0 0 3px 3px;
    transform: translateY(-10px);
    box-shadow: inset 0px 10px 12px -10px rgba(0, 0, 0, .3);
    color: var(--l-font);
}

.home__mobile-n {
    color: #b4b4b4;
    text-decoration: none;
    font-weight: 600;
    transition: .2s ease-in-out;
}

.home__mobile-n:hover {
    color: #fcfcfc;
}

.home__intoduction-container {
    display: grid;
    place-items: center;
    overflow: hidden;
}

.home__intoduction-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    overflow: hidden;
    min-height: 45vh;
    gap: 2rem 3rem;
    padding: 2rem;
    color: var(--d-font);
}

.home__intoduction-title {
    margin-bottom: .8rem;
}

.home__intoduction-subtitle {
    margin-bottom: .7rem;
}

.home__intoduction-text {
    color: var(--ld-font);
    line-height: 1.5rem;
}

.home__intoduction-img {
    height: 100%;
    max-height: 280px;
    max-width: 250px;
    min-width: 200px;
    overflow: hidden;
    border-radius: 6px;
    aspect-ratio: 4/5;
}


.home__intoduction-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
}

.home__reference-section {
    min-height: 40vh;
}

.home__reference-container {
    background: rgba(49, 49, 49, .75);
    min-height: 40vh;
    padding: 3rem 2rem;
    color: var(--l-font);
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__reference-box {
    max-width: 900px;
}

.home__reference-title {
    margin-bottom: 1.5rem;
}

.home__reference-text {
    margin-bottom: 2rem;
    line-height: 1.5rem;
}

.home__service-container {
    padding: 3rem 2rem;
    color: var(--d-font);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home__service-box {
    max-width: 1000px;
}

.home__service-title {
    text-align: center;
    margin-bottom: 1rem;
}

.home__service-text {
    color: var(--ld-font);
    line-height: 1.5rem;
    margin-bottom: 2rem;
}

.home__service-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin: 0 0 2rem;
}

.home__service-grid-item {
    background: white;
    border-radius: 8px;
    overflow: hidden;
}

.home__service-grid-item img {
    aspect-ratio: 1/.7;
    object-fit: cover;
    width: 100%;
    max-height: 200px;
}

.home__service-grid-title {
    text-align: center;
    margin: .3rem 0 1rem;
    padding: 0 .5rem;
}

.home__service-grid-text {
    color: var(--ld-font);
    padding: 0 1rem .8rem;
    font-size: .9rem;
    line-height: 1.5rem;
}

.home__service-button2-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home__service-button2 {
    background: var(--m-brown);
    text-decoration: none;
    font-weight: 500;
    color: var(--d-font);
    padding: .4rem 1rem;
    border-radius: 1.5rem;
}

@media screen and (max-width: 960px) {
    .home__subtitle {
        font-size: 1.3rem;
    }

    .home__title {
        margin: 1rem 0;
        font-size: 2.8rem;
    }

    .home__p {
        font-size: 1rem;
        line-height: 2rem;
    }

    .home__hero-box {
        width: 100%;
        max-width: 95%;
    }

    .home__p {
        line-height: 1.8rem;
    }

    .home__service-grid {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media screen and (max-width: 800px) {
    .home__nav-box {
        min-height: 130vh;
    }

    .home__hero-box {
        flex-direction: column;
        max-width: 100%;
        padding: 1rem 0;
    }

    .home__contact-box {
        width: 60%;
    }

    .home__contact-pn-box {
        width: 60%;
    }
}

@media screen and (max-width: 650px) {
    .home__intoduction-box {
        flex-direction: column-reverse;
    }

    .home__intoduction-img {
        max-width: 100%;
        max-height: 250px;
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .home__subtitle {
        font-size: 1.1rem;
    }

    .home__title {
        font-size: 2.4rem;
    }

    .home__p {
        font-size: 1rem;
    }

    .home__contact-container {
        width: 100%;
    }

    .home__contact-box {
        width: 90%;
    }

    .home__contact-pn-box {
        width: 90%;
    }

    .home__intoduction-img {
        max-height: 180px;
    }

    .home__service-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}