.nav__bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .2s ease-in-out;
    z-index: 10;
}

.cls-1 {
    fill: var(--l-font);
    stroke-width: 0px;
}

.nav__container {
    position: relative;
    width: 100%;
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
}

.logo svg {
    margin-top: 1.3rem;
    width: 110px;
    margin-left: 1rem;
    transition: .2s ease-in-out;
}

.nav__title {
    display: none;
    text-decoration: none;
    color: var(--d-font);
    font-weight: 600;
    font-size: 2em;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.nav__links-h {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 .2rem;
}

.nav__link-h {
    color: var(--l-font);
    font-size: 1.1em;
    padding: .3em .8rem;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    transition: .2s ease-in-out;
}

.nav__link-h-button {
    color: var(--d-font);
    background: var(--m-brown);
    padding: .3em 1rem;
    border-radius: 1.5rem;
    font-size: 1.1em;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    margin-left: 1rem;
    transition: .2s ease-in-out;
}

.nav__link-h:hover {

    background: var(--l-gray);
    color: var(--d-font);
}

.nav__link-h-button:hover {
    color: var(--d-font);
    background: #ffffff;
}

.nav__links-v {
    position: absolute;
    top: 105px;
    background: var(--l-gray);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0px 10px 12px -10px rgba(0, 0, 0, .1);
    width: 100%;
    overflow: hidden;
    transition: .2s ease-in-out;
    z-index: 10;
}


.nav__link-v {
    color: var(--d-font);
    font-size: 1em;
    padding: .7em .8rem;
    font-weight: 500;
    text-align: center;
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    transition: .2s ease-in-out;
    border: .5px solid #e7e7e7;
}


.nav__link-v:hover {
    background: #d1d1d1;
}

.nav__button {
    position: relative;
    height: 40px;
    aspect-ratio: 1/1;
    padding: 10px;
    border-radius: 3px;
    background: var(--m-brown);
    cursor: pointer;
    transition: .2s ease-in-out;
    display: none;
    margin-right: 1rem;
}

.nav__button:hover {
    background: #cec6b1;
}

.nav__line {
    background: var(--d-font);
}

.nav__line:nth-child(1) {
    position: absolute;
    height: 4px;
    width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .2s ease-in-out;
}

.nav__button.active .nav__line:nth-child(1) {
    position: absolute;
    height: 4px;
    width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.nav__line:nth-child(2) {
    position: absolute;
    height: 4px;
    width: 18px;
    top: 25%;
    left: 50%;
    transform: translate(-85%, -50%);
    transition: .2s ease-in-out;
}

.nav__button.active .nav__line:nth-child(2) {
    position: absolute;
    height: 4px;
    width: 16px;
    top: 25%;
    left: 50%;
    transform: translate(-80%, 80%) rotate(45deg);
}

.nav__line:nth-child(3) {
    position: absolute;
    height: 4px;
    width: 18px;
    top: 75%;
    left: 50%;
    transform: translate(-15%, -50%);
    transition: .2s ease-in-out;
}

.nav__button.active .nav__line:nth-child(3) {
    position: absolute;
    height: 4px;
    width: 18px;
    top: 75%;
    left: 50%;
    transform: translate(-27%, -195%) rotate(45deg);
}

@media screen and (max-width: 1200px) {
    .logo svg {
        width: 80px;
    }
}

@media screen and (max-width: 960px) {
    .nav__bar {
        background: var(--l-gray);
    }

    .nav__container {
        max-width: 90%;
    }

    .cls-1 {
        fill: var(--d-font);
    }

    .nav__title {
        display: block;
    }

    .nav__button {
        display: block;
    }

    .nav__links-h {
        display: none;
    }

    .nav__links-v.active {
        display: flex;
    }
}


@media screen and (max-width: 500px) {
    .nav__container {
        max-width: 100%;
    }

    .logo svg {
        width: 70px;
    }

    .nav__title {
        display: none;
    }

    .nav__links-v {
        top: 95px;
    }

    .nav__link-v {
        text-align: start;
        padding-left: 4rem;
    }
}