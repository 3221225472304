.home__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.home__name,
.home__email {
    padding: .4rem .8rem;
    border-radius: 3px;
    outline: none;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .1);
    border: 1px solid white;
    transition: .2s ease-in-out;
    font-size: var(--input-f-s);
}


.home__phonenumber-box {
    display: flex;
    background: white;
    border: 1px solid white;
    padding-left: .8rem;
    border-radius: 3px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .1);
    transition: .2s ease-in-out;
}

.home__phonenumber-label {
    font-size: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
    color: rgb(169, 169, 169);
    font-size: var(--input-f-s);
}

.home__phonenumber-input {
    width: 100%;
    border: none;
    border-radius: 3px;
    padding: .4rem .4rem .4rem .2rem;
    outline: none;
    font-size: var(--input-f-s);
}

.home__select-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .1);
    background: white;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid white;
    position: relative;
    transition: .2s ease-in-out;
}

.home__select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    padding: .4rem 0 .4rem .8rem;
    outline: none;
    width: 100%;
    background: transparent;
    cursor: pointer;
    font-size: var(--input-f-s);
    z-index: 2;
}

.home__select-box span {
    position: absolute;
    right: .8rem;
    color: rgb(169, 169, 169);
    font-size: 1.1rem;
    z-index: 1;
}

.home__text {
    border: none;
    padding: .4rem .4rem .4rem .8rem;
    border-radius: 3px;
    outline: none;
    resize: none;
    font-size: var(--input-f-s);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .1);
    border: 1px solid white;
    transition: .2s ease-in-out;
}

.home__submit {
    border: none;
    padding: .4rem;
    border-radius: 3px;
    outline: none;
    background: var(--m-brown);
    cursor: pointer;
    color: var(--d-font);
    font-weight: 600;
    font-size: .85rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .1);
    transition: .2s ease-in-out;
}

.home__submit.successful {
    background: rgb(143, 210, 140);
}

.home__submit.unsuccessful {
    background: rgb(221, 131, 131);
    color: var(--l-font);
}

.home__submit:hover {
    scale: 1.03;
}

.error {
    border: 1px solid red;
}

.shaky {
    animation: shake .5s ease-in-out;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    15%,
    45%,
    75% {
        transform: translateX(-3px);
    }

    30%,
    40%,
    60%,
    90% {
        transform: translateX(3px);
    }
}