.reference__img-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sekeletonloader {
    position: relative;
    display: grid;
    place-items: center;
    background: #dadada;
    border-radius: .3rem;
    max-height: 230px;
    max-width: 230px;
    aspect-ratio: 1;
    height: 100%;
    width: 100%;
}

.sekeletonloader .material-symbols-outlined {
    font-size: clamp(4em, 10vw, 5em);
    background-size: 200% 100%;
    color: wheat;
    animation: nebulahero 3s infinite;
}

@keyframes nebulahero {
    0% {
        color: rgb(183, 183, 183);
    }

    50% {
        color: rgb(146, 146, 146);
    }

    100% {
        color: rgb(183, 183, 183);
    }
}

.reference__img {
    position: absolute;
    border-radius: .3rem;
    max-width: 230px;
    aspect-ratio: 1;
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, .3);
    z-index: 2;
}

.reference__img[src=""] {
    display: none;
}