/* .reference__picture-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    background: var(--l-gray);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
}

.reference__photo-title {
    color: var(--d-font);
    margin-bottom: .8rem;
    font-size: 2.5em;
}

.reference__picture-box {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 1rem;
    overflow: hidden;
    padding: 1rem;
}



@media screen and (max-width: 1000px) {
    .reference__photo-title {
        font-size: 2em;
    }
}

@media screen and (max-width: 600px) {
    .reference__photo-title {
        font-size: 1.8em;
    }

    .reference__picture-box {
        grid-template-columns: repeat(2, 1fr);
        padding: .2rem;
        gap: .8rem;
    }
}

@media screen and (max-width: 400px) {
    .reference__picture-container {
        padding: .6rem 1rem 1rem;
    }

    .reference__photo-title {
        font-size: 1.6em;
        margin-bottom: .4rem;
    }
}

@media screen and (max-width: 320px) {
    .reference__picture-container {
        padding: .6rem 1rem 1rem;
    }

    .reference__photo-title {
        font-size: 1.4em;
        margin-bottom: .4rem;
    }
} */



.reference__picture-container {
    width: 100%;
    max-width: 1200px;
}

.reference__photo-title {
    display: inline-block;
    position: relative;
    font-weight: 600;
    padding: .4rem 1rem;
    border-radius: .3rem .3rem 0 0;
    font-size: clamp(1em, 7vw, 1.5em);
    color: var(--l-font);
    background: var(--d-brown);
    z-index: 1;
}

.reference__picture-box {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 1rem;
    background: var(--d-brown);
    box-shadow: 0px 8px 8px rgba(0, 0, 0, .2);
    border-radius: .3rem;
    padding: 1rem;
    z-index: 2;
}

@media screen and (max-width: 800px) {

    .reference__photo-title {
        text-align: center;
    }
}

@media screen and (max-width: 500px) {

    .reference__photo-title {
        width: 100%;
    }

    .reference__picture-box {
        grid-template-columns: repeat(2, 1fr);
    }
}